import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import './components/index.css'
import App from './App.vue'
import Home_page from './views/Home_page.vue';
import Event_page from './views/Event_page.vue';
import modal_page from './views/modal_page.vue';
import PrimeVue from 'primevue/config';
import user_regis from "./views/user_regis.vue";
import regis_successful from "./views/regis_successful.vue";
import Info_page from './components/info_page.vue';
import AboutView from './components/AboutView.vue';



// import f_password from "./views/f_password.vue";
// import Login_page from "./views/Login_page.vue";
// import signup_page from "./views/signup_page.vue";


const routes = [
  // { path: "/", component: Login_page },
  { path: "/", component: Home_page },
  { path: "/Event_page", component: Event_page },
  { path: "/modal_page", component: modal_page },
  { path: "/user_regis/:conferenceId", component: user_regis },
  { path: "/user_regis", component: user_regis },
  { path: "/regis_successful", component: regis_successful },
  { path: "/info_page", component: Info_page },
  { path: "/AboutView", component: AboutView}
];

  // { path: "/signup_page", component: signup_page },
  // { path: "/f_password", component: f_password },
  // { path: "/Login_page", component: Login_page },


const router = createRouter({
    history: createWebHistory(),
    routes
});


createApp(App).use(router, PrimeVue).mount('#app');


