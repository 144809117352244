<template>
    <div>
        <Home_page />
    </div>
</template>

<script>
  import Home_page from '@/components/Home_page.vue';

  export default {
  components: {
    Home_page
  }
}
</script>