<template>
    <div>
        <regis_successful />
    </div>
</template>


<script>
  import regis_successful from '@/components/regis_successful.vue';

  export default {
  components: {
    regis_successful
  }
}
</script>