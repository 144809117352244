<template>
    <div class='about'>
        <h1>{{ API_URL }}</h1>
    </div>
</template>

<script>
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
    data(){
        return{
            API_URL: process.env.VUE_APP_PATH_START
        }
    },

    methods: {
    }
})
</script>