<template>
  <div class="p-5 space-x-6 w-[100vw] bg-gradient-to-b from-orange-100 to-red-100 h-[100vh] flex justify-center items-center">
    <div class="w-full bg-white p-10 rounded-lg shadow-md h-96 overflow-y-auto relative" :style="{ height: '95%' }">
      <button @click="goBack" class="absolute top-4 left-4">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-6 w-6 text-gray-500">
          <path fill="currentColor" d="M15.41 16.58L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
        </svg>
      </button>
      <button @click="downloadData" class="absolute top-4 right-10 flex flex-col items-center text-gray-500">
        <i class="pt-1 fa-regular fa-circle-down" style="color: #5c5c5c;"></i>
      </button>
      <table class="w-full border-collapse border border-gray-200 mt-8">
        <thead>
          <tr>
            <th class="px-4 py-2 border border-gray-200">Full Name</th>
            <th class="px-4 py-2 border border-gray-200">Organisation</th>
            <th class="px-4 py-2 border border-gray-200">Email Address</th>
            <th class="px-4 py-2 border border-gray-200">Tel Number</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(attendee, index) in attendees" :key="index">
            <td class="px-4 py-2 border border-gray-200">{{ attendee.Fullname }}</td>
            <td class="px-4 py-2 border border-gray-200">{{ attendee.Organisation }}</td>
            <td class="px-4 py-2 border border-gray-200">{{ attendee.EmailAddress }}</td>
            <td class="px-4 py-2 border border-gray-200">{{ attendee.TelNumber }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "InfoPage",
  data() {
    return {
      attendees: []
    };
  },
  mounted() {
    const conferenceName = this.$route.query.conferenceName;
    const fetchAttendees = async () => {
      try {
        const response = await axios.post('https://registration-server-ama-nanas-projects.vercel.app/api/user_details', {
          tableName: conferenceName,
        });
        this.attendees = response.data;
      } catch (error) {
        console.error('Error fetching attendees:', error);
      }
    };
    fetchAttendees();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    downloadData() {
      const docDefinition = {
        content: [
          {
            table: {
              headerRows: 1,
              widths: ['auto', 'auto', 'auto', 'auto'], // Adjust column widths as needed
              body: [
                ['Full Name', 'Organisation', 'Email Address', 'Tel Number'],
                ...this.attendees.map(attendee => [attendee.Fullname, attendee.Organisation, attendee.EmailAddress, attendee.TelNumber])
              ]
            }
          }
        ]
      };
      pdfMake.createPdf(docDefinition).download('attendees.pdf');
    }
  }
};
</script>
