<template>
    <div>
        <Event_page />
    </div>
</template>

<script>
  import Event_page from '@/components/Event_page.vue';

  export default {
  components: {
    Event_page
  }
}
</script>
