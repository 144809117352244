<template>
  <div
    class="p-5 space-x-6 w-[100vw] bg-gradient-to-b from-orange-100 to-red-100 h-[100vh] flex justify-center items-center"
  >
    <div class="relative w-[3rem] bg-red-600 rounded-lg shadow-md h-full">
      <!-- button 1 -->
      <button @click="goToHomePage">
        <i
          class="pl-3.5 pt-7 fa-regular fa-calendar"
          style="color: #ffffff"
        ></i>
      </button>

      <!-- button 2 -->
      <button>
        <i class="pl-3 pt-7 fa-solid fa-user-group" style="color: #ffffff"></i>
      </button>

      <!-- button 3 -->
      <button>
        <i
          class="absolute bottom-9 inset-x-0 pt-7 fa-solid fa-user"
          style="color: #ffffff"
        ></i>
      </button>

      <!-- white background -->
    </div>
    <div
      class="w-full bg-white p-63 rounded-lg shadow-md h-5/6 p-10"
      :style="{ height: '95%' }"
    >
      <h2 class="text-2xl font-bold mb-4" style="color: #cf2027">
        Conferences
      </h2>

      <!-- Conference List -->
      <div>
        <ul v-if="conferences.length > 0" class="divide-y divide-gray-200">
          <li
            v-for="conference in conferences"
            :key="conference.id"
            class="conference-name hover:bg-red-100"
            @contextmenu.prevent="showContextMenu(conference, $event)"
          >
            <div class="flex justify-between">
              <div class="flex-1">
                <p
                  class="text-sm font-semibold"
                  @mouseover="hoveredConference = conference"
                  @mouseleave="hoveredConference = null"
                >
                  {{ conference.name }}
                </p>
              </div>
            </div>
          </li>
        </ul>
        <p v-else class="text-gray-500">No upcoming conferences available</p>
      </div>

      <work_exam />

      <!-- Context Menu -->
      <div
        v-if="showMenu"
        ref="contextMenu"
        class="absolute z-10 bg-white shadow-md context-menu"
        :style="{ top: menuPosition.y + 'px', left: menuPosition.x + 'px' }"
        @mouseleave="hideContextMenu"
      >
        <ul class="py-2">
          <li @click="viewParticipantsInfo(clickedConference.name)" class="hover:bg-red-100 cursor-pointer">
            View Participants Info
          </li>
          <li @click="deleteConference(clickedConference.id)" class="hover:bg-red-100 cursor-pointer">
            Delete Conference
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();

const conferences = ref([]);
const hoveredConference = ref(null);
const clickedConference = ref(null);
const showMenu = ref(false);
const menuPosition = ref({ x: 0, y: 0 });

const fetchConferences = async () => {
  try {
    const response = await axios.get(
      "https://registration-server-ama-nanas-projects.vercel.app/api/conferences_table"
    );
    conferences.value = response.data.map((conference) => ({
      id: conference.id,
      name: conference.name,
      date: conference.startDate,
    }));
  } catch (error) {
    console.error("Error fetching conferences:", error);
    if (error.response) {
      console.log("Axios Error Details:", error.response);
    }
  }
};

const showContextMenu = (conference, event) => {
  clickedConference.value = conference;
  menuPosition.value = { x: event.clientX, y: event.clientY };
  showMenu.value = true;
  window.addEventListener("click", hideContextMenu);
};

const hideContextMenu = () => {
  showMenu.value = false;
  window.removeEventListener("click", hideContextMenu);
};

const viewParticipantsInfo = (conferenceName) => {
  router.push({ path: "/info_page", query: { conferenceName } });
};

const deleteConference = async () => {
  try {
    const id = clickedConference.value.name;
    if (id) {
      await axios.delete(`https://registration-server-ama-nanas-projects.vercel.app/api/delete/${id}`);
      // After successful deletion, fetch conferences again to update the list
      await fetchConferences();
      hideContextMenu(); // Hide the context menu after deletion
    } else {
      console.error("Conference ID not found.");
    }
  } catch (error) {
    console.error("Error deleting conference:", error);
    // Handle error as needed
  }
};

onMounted(fetchConferences);
onUnmounted(() => {
  window.removeEventListener("click", hideContextMenu);
});
</script>

<script>
export default {
  name: "App",

  methods: {
    goToEventPage() {
      this.$router.push("/Event_page");
    },

    goToHomePage() {
      this.$router.push("/");
    },
  },
};
</script>

<style>
/* Add your component-specific styles here */
.conference-name {
  font-size: 14px;
  font-weight: bold;
  padding: 20px 0; /* Double the padding to increase spacing */
}
.context-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.context-menu li {
  padding: 10px;
  cursor: pointer;
}
.context-menu li:hover {
  background-color: #fde9e9;
}
/* Modal styles */
.modal-overlay {
  z-index: 50;
}
.modal-container {
  position: relative;
  z-index: 50;
  padding: 20px;
}

/* Added padding for the context menu button */
.context-menu button {
  padding-top: 10px; /* Adjust as needed */
  padding-bottom: 10px; /* Adjust as needed */
  padding-right: 10px;
  padding-left: 10px;
}
</style>
