<template>
  <div
    class="p-5 space-x-6 w-[100vw] bg-gradient-to-b from-orange-100 to-red-100 h-[100vh] flex justify-center items-center"
  >

    <!-- <modal_page/> -->
    <div class="relative w-[3rem] bg-red-600 rounded-lg shadow-md h-full">
      <button @click="gotoHomePage">
        <i
          class="pl-3.5 pt-7 fa-regular fa-calendar"
          style="
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        ></i>
      </button>
      <!--button 2-->
      <button @click="goToEventPage">
        <i
          class="pl-3 pt-7 fa-solid fa-user-group"
          style="
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        ></i>
      </button>
      <button>
        <i
          class="absolute bottom-9 inset-x-0 pt-7 fa-solid fa-user"
          style="
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        ></i>
      </button>
    </div>
    <div
      class="w-full bg-white p-10 rounded-lg shadow-md h-96 overflow-y-auto"
      :style="{ height: '95%' }">
      <h2 class="text-2xl font-bold mb-4" style="color: #cf2027">
        Upcoming Conferences
      </h2>
      <div>
        <ul v-if="upcomingConferences.length > 0" class="divide-y divide-gray-200">
          <li
            v-for="conference in upcomingConferences"
            :key="conference.id"
            class="py-4 hover:bg-red-100"
            @contextmenu.prevent="showContextMenu(conference, $event)"
          >
            <div class="flex justify-between">
              <div class="flex-1">
                <p
                  class="text-sm font-semibold"
                  @mouseover="hoveredConference = conference"
                  @mouseleave="hoveredConference = null"
                >
                  {{ conference.name }}
                </p>
              </div>
                
              <div class="flex-1 text-right">
                <p class="text-sm text-gray-500">{{ formatDate(conference.date) }}</p>
                <p class="text-xs text-gray-500">{{ isConferenceUpcoming(conference.date) }}</p>
              </div>
            </div>
          </li>
        </ul>
        <p v-else class="text-gray-500">No upcoming conferences available</p>
      </div>

      <modal_page/>
      
      <!-- Context Menu -->
      <div
        v-if="showMenu"
        ref="contextMenu"
        class="absolute z-10 bg-white shadow-md context-menu"
        :style="{ top: menuPosition.y + 'px', left: menuPosition.x + 'px' }"
      >
        <ul class="py-2">
          <li @click="copyConferenceLink" class="hover:bg-red-100">Copy Conference Link</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';



const conferences = ref([]);
const hoveredConference = ref(null);
const clickedConference = ref(null);
const showMenu = ref(false);
const menuPosition = ref({ x: 0, y: 0 });

const fetchConferences = async () => {
  try {
    const response = await axios.get('https://registration-server-ama-nanas-projects.vercel.app/api/conferences_table');
    conferences.value = response.data.map(conference => ({
      id: conference.id,
      name: conference.name,
      date: conference.startDate
    }));
  } catch (error) {
    console.error('Error fetching conferences:', error);
    if (error.response) {
      console.log('Axios Error Details:', error.response);
    }
  }
};

const isConferenceUpcoming = (date) => {
  const currentDate = new Date();
  const conferenceDate = new Date(date);
  return currentDate < conferenceDate ? 'Upcoming' : 'Past';
};

const formatDate = (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
};

const upcomingConferences = computed(() => {
  return conferences.value.filter(conference => isConferenceUpcoming(conference.date) === 'Upcoming');
});

const showContextMenu = (conference, event) => {
  clickedConference.value = conference;
  menuPosition.value = { x: event.clientX, y: event.clientY };
  showMenu.value = true;
  window.addEventListener('click', hideContextMenuOutside);
};

const hideContextMenu = () => {
  showMenu.value = false;
  window.removeEventListener('click', hideContextMenuOutside);
};

const hideContextMenuOutside = (event) => {
  const contextMenu = document.querySelector('.context-menu');
  if (contextMenu && !contextMenu.contains(event.target)) {
    hideContextMenu();
  }
};

const copyConferenceLink = () => {
  if (clickedConference.value) {
    const conferenceLink = `https://registration-app-13t5.vercel.app/user_regis/${clickedConference.value.name}`;
console.log("im here", clickedConference.value.name)
    const tempInput = document.createElement("input");
    tempInput.style.position = "absolute";
    tempInput.style.left = "-1000px";
    tempInput.style.top = "-1000px";
    tempInput.value = conferenceLink;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);


    // Provide feedback to the user
   
  }
   alert("Conference link copied to clipboard!");
console.log("im here", clickedConference.value.name)
  hideContextMenu();
};

onMounted(fetchConferences);
</script>

<script>
import modal_page from "@/components/modal_page.vue";

export default {
  
  name: "App",
  components: {
    modal_page,
  },
  methods: {
    goToEventPage() {
      this.$router.push("/Event_page");
    },

    goToHomePage() {
      this.$router.push("/");
    },

  }
}
</script>



<style>
/* Add hover styles for each icon */
.fa-calendar:hover {
  filter: brightness(80%);
}

.fa-user-group:hover {
  filter: brightness(80%);
}

.fa-gear:hover {
  filter: brightness(80%);
}

.fa-user:hover {
  filter: brightness(80%);
}

.fa-circle-plus:hover {
  filter: brightness(80%);
}

li {
  cursor: pointer;
  padding: 5px;
}

li.selected {
  font-weight: bold;
  color: #cf2027;
}

li:hover {
  background-color: #e3f2fd;
}
</style>
