<template>
    <div>
        <modal_page />
        <input type="text" id="dateRangePickerId" />
    </div>
</template>

<script>
import modal_page from '@/components/modal_page.vue';
import DateRangePicker from 'flowbite-datepicker/DateRangePicker';

export default {
  components: {
    modal_page
  },
  mounted() {
    // Initialize DateRangePicker when the component is mounted
    const dateRangePickerEl = document.getElementById('dateRangePickerId');
    new DateRangePicker(dateRangePickerEl, {
      // options
    });
  }
}
</script>
