<template>
    <div>
        <user_regis />
    </div>
</template>

<script>
  import user_regis from '@/components/user_regis.vue';

  export default {
  components: {
    user_regis
  }
}
</script>