<template>
  <div class="relative overflow-hidden">
    <!-- Circle in the top left corner -->
    <div
      class="absolute left-0 top-0 transform -translate-x-1/3 w-44 h-44 bg-gradient-to-b from-orange-500 to-red-600 rounded-full shadow-lg"
    ></div>

    <!-- Circle in the bottom left corner -->
    <div
      class="absolute left-0 bottom-0 transform -translate-x-1/3 w-44 h-44 bg-gradient-to-b from-orange-500 to-red-600 rounded-full shadow-lg"
    ></div>

    <!-- Circle in the top right corner -->
    <div
      class="absolute top-2 right-0 w-32 h-32 bg-gradient-to-b from-orange-500 to-red-600 rounded-full shadow-lg move-right-to-left"
    ></div>

    <!-- Circle in the bottom right corner -->
    <div
      class="absolute bottom-20 end-20 right-0 w-20 h-20 bg-gradient-to-b from-orange-500 to-red-600 rounded-full shadow-lg move-side-to-side"
    ></div>

    <!-- Background content -->
    <div
      class="p-5 space-x-6 w-[100vw] bg-gradient-to-r from-red-600 to-orange-500 h-[100vh] flex justify-center items-center"
    >
      <!-- Your content goes here -->
    </div>

    <!-- Modal -->
    <div
      id="crud-modal"
      tabindex="-1"
      aria-hidden="true"
      class="fixed inset-0 overflow-y-auto flex justify-center items-center z-50"
    >
      <div
        class="bg-white bg-opacity-10 backdrop-filter absolute inset-0"
      ></div>
      <div
        class="relative bg-white bg-opacity-30 rounded-lg shadow-lg w-[90vw] h-6/6  max-h-[calc(80vh-0rem)] p-6 flex flex-col justify-between"
      >
        <!-- Modal content -->
        <div class="relative">

          <!-- Modal header -->
          <div class="flex items-center justify-between border-b pb-4 mb-4">
            <img :src="logo" alt="Conference Logo" class="h-10 w-auto mr-8" />
            <h3 class="text-lg font-semibold text-white flex-grow break-word">
              Register for {{ formattedConferenceId }}
            </h3>
          </div>

          <!-- Modal body -->
         <form @submit.prevent="register" :disabled="!registrationEnabled">

            <!-- Full Name Input -->
            <div class="mb-4">
              <label
                for="full-name"
                class="block text-sm font-medium text-white"
                >Full Name</label
              >
              <input
                v-model="fullName"
                type="text"
                id="full-name"
                class="mt-1 block w-full rounded-md border-none bg-white bg-opacity-20 text-white placeholder-white focus:outline-none"
                required
              />
              <!-- Error message for Full Name
              <p class="text-red-500 mt-1" v-if="!fullName && touched.fullName && submitted">Please enter your full name.</p> -->
            </div>
            
            <!-- Organization Input -->
            <div class="mb-4">
              <label
                for="organization"
                class="block text-sm font-medium text-white"
                >Organisation</label
              >
              <input
                v-model="organization"
                type="text"
                id="organization"
                class="mt-1 block w-full rounded-md border-none bg-white bg-opacity-20 text-white placeholder-white focus:outline-none"
                required
              />
              <!-- Error message for Organization
              <p class="text-red-500 mt-1" v-if="!organization && touched.organization && submitted">Please enter your organization.</p> -->
            </div>

            <!-- Email Address Input -->
            <div class="mb-4">
              <label for="email" class="block text-sm font-medium text-white"
                >Email Address</label
              >
              <input
                v-model="email"
                type="email"
                id="email"
                class="mt-1 block w-full rounded-md border-none bg-white bg-opacity-20 text-white placeholder-white focus:outline-none"
                required
              />
              <!-- Error message for Email Address
             <p class="text-red-500 mt-1" v-if="!email && touched.email && submitted">Please enter your email address.</p> -->
            </div>

            <!-- Telephone Input -->
            <div class="mb-4">
              <label for="telephone" class="block text-sm font-medium text-white">Telephone Number</label>
              <input
                v-model="telephone"
                @input="filterNonNumeric"
                type="tel"
                id="telephone"
                pattern="[0-9]*"
                class="mt-1 block w-full rounded-md border-none bg-white bg-opacity-20 text-white placeholder-white focus:outline-none"
                required
                />  
                <!-- Error message for Telephone Number
            <p class="text-red-500 mt-1" v-if="!telephone && touched.telephone && submitted">Please enter your telephone number.</p> -->
            </div>

            <!-- Registration button -->
            <button
              type="submit"
              class="bg-red-600 text-white w-full px-8 py-2 rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-700"
            >
              Register
            </button>
          </form>

          <!-- Display registration status -->
          <p class="text-white mt-4" v-if="message">{{ message }}</p>

        </div>
        <!-- Modal footer -->
        <div>
          <div
            class="flex items-center justify-center border-b pb-4 mb-4"
          ></div>
           <!-- Loader -->
          <div v-if="loading" class="loader"></div>
          <!-- <button type="submit" class="bg-red-600 text-white w-full px-8 py-2 rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-700">
            Register
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import logo from "@/assets/logo.png";
import { format } from 'date-fns';


export default {
  name: "GradientBackgroundWithAdjustedCircles",
  props: {
    conferenceName: { // Passing the conference name
      type: String,
      required: true,
      loading: false 
    }
  },
  data() {
    return {
      fullName: "",
      organization: "",
      email: "",
      telephone: "",
      conferenceId: "Your_Conference_Id",
      message: "", // Add message variable
      registrationEnabled: false, // Add registrationEnabled variable
      touched: {
        fullName: false,
        organization: false,
        email: false,
        telephone: false
      },
      submitted: false,
      logo: logo // Assign the imported logo to a data property
    };
  },
  
  mounted() {
    // Extract conferenceId from the route parameters and decode it
    this.conferenceId = decodeURIComponent(this.$route.params.conferenceId);
    console.log("Conference ID111:", this.conferenceId);
  },
  methods: {
    filterNonNumeric() {
      // Replace any non-numeric character with an empty string
      this.telephone = this.telephone.replace(/\D/g, '');
    },
    async register() {
      // Set submitted to true when the form is submitted
      this.submitted = true;
      
      // Check if all fields are filled
      if (!this.registrationEnabled) {
        return; // Prevent form submission if not all fields are filled
      }
      console.log("Conference ID222:", this.conferenceId);

      // Redirect to successful registration page immediately
      this.$router.push("/regis_successful");

      try {
        const response = await axios.post(
          "https://registration-server-ama-nanas-projects.vercel.app/api/register",
          {
            fullName: this.fullName,
            organization: this.organization,
            email: this.email,
            telephone: this.telephone,
            conferenceId: this.conferenceId
          }
        );
        console.log(response.data);
        if (response.data.success) { 
          
          // Assuming your response contains a 'success' field

            const additionalDetailsResponse = await axios.get(
        `https://registration-server-ama-nanas-projects.vercel.app/api/event_details/${this.conferenceId}`
        // Add any necessary query parameters or headers here
      );
             console.log('Additional Details Response:', additionalDetailsResponse);

      // Extract the required details from the response
      const additionalDetails = additionalDetailsResponse.data;
      
        // Format the dates
        const formattedStartDate = format(new Date(additionalDetails.startDate), 'MMMM d, yyyy');
        const formattedEndDate = format(new Date(additionalDetails.endDate), 'MMMM d, yyyy');
        const formattedStartTime = format(new Date(additionalDetails.startDate), 'h:mm a');
        const formattedEndTime = format(new Date(additionalDetails.endDate), 'h:mm a');

          // Send email
          const emailData = {
            fullName: this.fullName,
            email: this.email,
            conferenceName: this.conferenceId,
            startDate: formattedStartDate,
            endDate:formattedEndDate,
            startTime: formattedStartTime,
            endTime: formattedEndTime,
            venue: additionalDetails.venue,
            // Add any other necessary data for the email here
          };
           console.log('Email Data:', emailData);

          // Send email only if registration is successful
          axios.post('https://registration-server-ama-nanas-projects.vercel.app/api/sendEmail', emailData)
            .then(() => {
              console.log("Email sent successfully");
              // Redirect to successful registration page immediately
            })
            .catch(error => {
              console.error("Error sending email:", error);
              this.message = "Registration successful, but email sending failed. Please contact support."; // Update message on email sending failure
              // Clear message after 3 seconds
              setTimeout(() => {
                this.message = "";
              }, 3000);
            });
        } else {
          this.message = "Registration failed. Please try again."; // Update message on failure
        }
        // Clear message after 3 seconds
        setTimeout(() => {
          this.message = "";
        }, 3000);
      } catch (error) {
        console.error("Error registering:", error);
        this.message = "Registration failed. Please try again."; // Update message on failure
        // Clear message after 3 seconds
        setTimeout(() => {
          this.message = "";
        }, 3000);
      }
    },
    checkFields() {
      // Check if all fields are filled
      this.registrationEnabled =
        this.fullName !== "" &&
        this.organization !== "" &&
        this.email !== "" &&
        this.telephone !== "";
    }
  },
  watch: {
    // Watch for changes in form fields
    fullName: "checkFields",
    organization: "checkFields",
    email: "checkFields",
    telephone: "checkFields"
  },
  computed: {
    formattedConferenceId() {
      // Replace underscores with spaces
      return this.conferenceId.replace(/_/g, " ");
    }
  }
};
</script>


<style scoped>
.move-side-to-side {
  animation: moveSideToSide 10s ease-in-out infinite alternate;
}

.move-right-to-left {
  animation: moveRightToLeft 10s ease-in-out infinite alternate;
}

@keyframes moveSideToSide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes moveRightToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.break-all {
  word-break: break-all;
}

.break-word {
  overflow-wrap: break-word;
}

</style>
