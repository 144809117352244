<template>
  <!-- Modal toggle -->
  <button
    @click="toggleModal"
    class="absolute bottom-20 inset-x-12 pt-7 fa-solid fa-circle-plus fa-2x"
    style="
      color: #cf2027;
      display: flex;
      justify-content: right;
      align-items: center;
    "
    type="button"
  ></button>

  <!-- Main modal -->
  <div
    v-if="modalOpen"
    class="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-full bg-gray-800 bg-opacity-50"
  >
    <div
      class="relative p-4 w-full max-w-md"
      style="width: 600px; max-width: none"
    >
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow" style="height: 400px">

        <!-- Modal header -->
        <div class="flex items-center justify-between p-4 border-b rounded-t">
          <h3 class="text-xl font-semibold text-red-600">
            Create a Conference
          </h3>
          <button
            @click="toggleModal"
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
          >
            X
            <span class="sr-only">Close modal</span>
          </button>
        </div>

        <!-- Modal body -->
        <div class="p-4 md:p-5">
          <div class="col-span-2">

            <!-- Name input field -->
            <div class="mb-4">
              <label for="name" class="block text-sm font-medium text-gray-700"
                >Name</label
              >
              <input
                type="text"
                id="name"
                v-model="name"
                class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 bg-gray-50 sm:text-sm"
                placeholder="Enter conference name"
              />

              <!-- Venue Input Field -->
                <div class="mb-4">
            <label for="venue" class="pt-3 block text-sm font-medium text-gray-700"
            >Venue</label
            >
            <input
              type="text"
              id="venue"
              v-model="venue"
              class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 bg-gray-50 sm:text-sm"
              placeholder="Enter conference venue"
              />
            </div>
            </div>

            <!-- Start and end date pickers -->
            <div class="pt-2 flex">
              <flat-pickr
                v-model="startDate"
                class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                placeholder="Start date and time"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true, utc: true }"
              ></flat-pickr>
              <span class="mx-4 text-gray-500">to</span>
              <flat-pickr
                v-model="endDate"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                placeholder="End date and time"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true, utc: true }"
              ></flat-pickr>
            </div>
          </div>
        </div>

        <!-- Modal footer -->
        <div
          class="absolute bottom-0 left-0 right-0 bg-white border-t border-gray-200 rounded-b dark:border-gray-600"
        >
          <div
            class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600"
          >
            <button
              @click="generateLink"
              :disabled="!name || !startDate || !endDate"
              type="button"
              class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-oranhe-700 dark:focus:ring-red-800"
            >
              Generate Link
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Generated link modal -->
  <div
    v-if="linkModalOpen"
    class="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-full bg-gray-800 bg-opacity-50"
  >
    <div
      class="relative p-4 w-full max-w-md"
      style="width: 500px; max-width: none"
    >
      <div class="relative bg-white rounded-lg shadow" style="max-height: 90vh; overflow-y: auto;">
        <div class="flex items-center justify-between p-4 border-b rounded-t">
          <h3 class="text-xl font-semibold text-red-600">Generated Link</h3>
          <button
            @click="toggleLinkModal"
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
          >
            X
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <div class="p-4 md:p-5">
          <p class="text-gray-700">Here's your generated link:</p>
          <p class="text-blue-700">{{ generatedLink }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
// import { v4 as uuidv4 } from 'uuid';

export default {
  name: "modalComp",
  components: {
    flatPickr,
  },
  data() {
    return {
      modalOpen: false,
      linkModalOpen: false,
      name: "",
      venue: "",
      startDate: null,
      endDate: null,
      generatedLink: "",
    };
  },

    watch: {
    startDate(newValue) {
      // If startDate is selected, set endDate to the last day of the selected month and year
      if (newValue) {
        const endOfMonth = new Date(newValue);
        endOfMonth.setMonth(endOfMonth.getMonth() + 1);
        endOfMonth.setDate(0); // Setting to the last day of the previous month gives the last day of the selected month
        this.endDate = endOfMonth;
      }
    },
  },

  methods: {
    toggleModal() {
      this.modalOpen = !this.modalOpen;
    },
    toggleLinkModal() {
      this.linkModalOpen = !this.linkModalOpen;
    },

    generateLink() {
      // Generate a unique identifier for the conference
      const conferenceId = encodeURIComponent(this.name);

      // Encode the conference name to make it URL-safe
      // const encodedName = encodeURIComponent(this.name);

  // Construct the unique link using the conferenceId and encodedName
  const uniqueLink = `https://registration-app-13t5.vercel.app/user_regis/${conferenceId}`;

      // Make API request to create event with this.name, this.startDate, this.endDate, and uniqueLink
      // After successfully creating the event, set generatedLink and open the link modal
      this.generatedLink = uniqueLink; // Replace with the actual generated link

      // Make API request to create table and insert conference details
      axios
        .post(
          "https://registration-server-ama-nanas-projects.vercel.app/api/conferences",
          {
            name: this.name,
            startDate: this.startDate,
            endDate: this.endDate,
            uniqueLink: uniqueLink,
          }
        )
        .then(() => {
          // Handle success
          console.log("Conference created");
          this.name = "";
          (this.startDate = ""), (this.endDate = "");
        })
        .catch((error) => {
          // Handle error
          console.error("Error creating conference", error);
        });

      this.linkModalOpen = true;

      axios.post(
        "https://registration-server-ama-nanas-projects.vercel.app/api/conferences_table",
        {
          name: this.name,
          startDate: this.startDate,
          endDate: this.endDate,
          venue: this.venue,
        }
      );
    },
  },
};
</script>

<style scoped>
/* Your modal styles go here */
</style>
